import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, vModelText as _vModelText, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cfd6aa94"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "u-fontSize-vue--regular c-profile-legend" }
const _hoisted_2 = { class: "c-profile" }
const _hoisted_3 = ["aria-label"]
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 1,
  class: "c-profile-icon-overlay"
}
const _hoisted_6 = ["src"]
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "u-fontWeight--bold" }
const _hoisted_9 = {
  key: 0,
  class: "c-profile-subtitle"
}
const _hoisted_10 = { key: 0 }
const _hoisted_11 = {
  key: 1,
  class: "c-dividerWithText"
}
const _hoisted_12 = { class: "u-paddingHorizontal--medium u-marginVerticalUI--xSmall" }
const _hoisted_13 = {
  key: 1,
  class: "c-dividerWithText"
}
const _hoisted_14 = { class: "u-paddingHorizontal--medium u-marginVerticalUI--xSmall" }
const _hoisted_15 = {
  key: 2,
  class: "c-form-row u-marginBottomUI--xxSmall"
}
const _hoisted_16 = ["aria-label", "placeholder"]
const _hoisted_17 = ["aria-label", "placeholder", "value"]
const _hoisted_18 = {
  key: 4,
  class: "c-form-row"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_social_login = _resolveComponent("social-login")!
  const _component_avatar_uploader = _resolveComponent("avatar-uploader")!
  const _component_modal = _resolveComponent("modal")!
  const _directive_analytics = _resolveDirective("analytics")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("legend", _hoisted_1, _toDisplayString(_ctx.$t('Your Profile')), 1),
    (_ctx.isLoggedIn && !(_ctx.isPreviouslyReviewed && !_ctx.profile.socialMediaType))
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_2, [
            (!_ctx.profile.socialMediaType)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  (_ctx.isAddProfileImageModalEnabled)
                    ? _withDirectives((_openBlock(), _createElementBlock("button", {
                        key: 0,
                        onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.openAddProfileImageModal && _ctx.openAddProfileImageModal(...args)), ["prevent"])),
                        "aria-label": 
                        _ctx.profile.imageUrl
                            ? _ctx.$t('Edit Profile Picture')
                            : _ctx.$t('Add Profile Picture')
                    ,
                        class: _normalizeClass(
                        _ctx.profile.imageUrl
                            ? 'c-profile-customAvatar'
                            : 'c-profile-icon c-profile-icon--plus'
                    ),
                        type: "button"
                      }, [
                        (_ctx.profile.imageUrl)
                          ? (_openBlock(), _createElementBlock("img", {
                              key: 0,
                              src: _ctx.profile.customAvatarSource ?? _ctx.profile.imageUrl,
                              class: "c-profile-icon c-profile-icon--uploaded"
                            }, null, 8, _hoisted_4))
                          : _createCommentVNode("", true),
                        (_ctx.profile.imageUrl)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                              _createVNode(_component_icon, {
                                icon: "edit",
                                class: "c-icon--edit"
                              })
                            ]))
                          : (_openBlock(), _createBlock(_component_icon, {
                              key: 2,
                              icon: "plus",
                              class: "c-icon--profileImage"
                            }))
                      ], 10, _hoisted_3)), [
                        [_directive_analytics, { eventName: 'click-open-avatar-modal' }]
                      ])
                    : _createCommentVNode("", true)
                ], 64))
              : (_ctx.profile.socialMediaType === 'facebook')
                ? (_openBlock(), _createElementBlock("img", {
                    key: 1,
                    src: _ctx.profile.imageUrl,
                    class: "c-profile-icon c-profile-icon--facebook"
                  }, null, 8, _hoisted_6))
                : (_ctx.profile.socialMediaType === 'google')
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 2,
                      src: _ctx.profile.imageUrl,
                      class: "c-profile-icon c-profile-icon--google"
                    }, null, 8, _hoisted_7))
                  : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: _normalizeClass([_ctx.isAddProfileImageModalEnabled ? 'u-paddingLeft--medium' : 'u-paddingLeft--small', "c-profile-body"])
            }, [
              _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.displayName), 1),
              (_ctx.isEmailInputVisible)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.profile.email), 1))
                : _createCommentVNode("", true)
            ], 2),
            _withDirectives(_createElementVNode("a", {
              onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.logout()), ["prevent"])),
              href: "#",
              class: "c-profile-notYou"
            }, [
              _createTextVNode(_toDisplayString(_ctx.$t('Not you?')), 1)
            ], 512), [
              [_directive_analytics, { eventName: 'click-not-you' }]
            ])
          ]),
          _createVNode(_component_modal, {
            isModalVisible: _ctx.modal.isVisible,
            onResponse: _ctx.handleModalResponse
          }, {
            default: _withCtx(() => [
              (_ctx.isSocialLoginEnabled)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _createVNode(_component_social_login, {
                      onSignedIn: _cache[2] || (_cache[2] = ($event: any) => (_ctx.completedUpload()))
                    })
                  ]))
                : _createCommentVNode("", true),
              (_ctx.isSocialLoginEnabled && _ctx.isProfilePictureUploadEnabled)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('OR')), 1)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.isProfilePictureUploadEnabled)
                ? (_openBlock(), _createBlock(_component_avatar_uploader, {
                    key: 2,
                    onUploadComplete: _cache[3] || (_cache[3] = ($event: any) => (_ctx.completedUpload())),
                    isInModal: true
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["isModalVisible", "onResponse"])
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.isSocialLoginEnabled)
            ? (_openBlock(), _createBlock(_component_social_login, { key: 0 }))
            : _createCommentVNode("", true),
          (_ctx.isProfileDividerVisible)
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t('OR')), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.isNameInputVisible)
            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.profileName) = $event)),
                  "aria-label": _ctx.$t('Name'),
                  class: _normalizeClass([{ hasError: !_ctx.profileName && _ctx.hasSubmitted }, "c-input"]),
                  placeholder: _ctx.$t(_ctx.namePlaceholderText),
                  type: "text",
                  maxlength: "1000"
                }, null, 10, _hoisted_16), [
                  [_directive_analytics, {
                    eventName: 'change-profile-name',
                    label: 'profileNameLength'
                }, "change"],
                  [
                    _vModelText,
                    _ctx.profileName,
                    void 0,
                    { trim: true }
                  ]
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.isEmailInputVisible)
            ? (_openBlock(), _createElementBlock("div", {
                key: 3,
                class: _normalizeClass([{ 'u-marginBottomUI--xxSmall': _ctx.isProfilePictureUploadEnabled }, "c-form-row"])
              }, [
                _withDirectives(_createElementVNode("input", {
                  onInput: _cache[5] || (_cache[5] = ($event: any) => (_ctx.updateReviewerProfile({ email: $event.target.value }))),
                  "aria-label": _ctx.$t('Email Address'),
                  class: _normalizeClass([{ hasError: !_ctx.isEmailAddress && _ctx.hasSubmitted }, "c-input"]),
                  placeholder: _ctx.$t(_ctx.emailPlaceholderText),
                  value: _ctx.profile.email,
                  type: "text",
                  maxlength: "1000"
                }, null, 42, _hoisted_17), [
                  [_directive_analytics, {
                    eventName: 'change-profile-email',
                    label: 'profileEmailLength'
                }, "change"]
                ])
              ], 2))
            : _createCommentVNode("", true),
          (_ctx.isProfilePictureUploadEnabled)
            ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                _createVNode(_component_avatar_uploader, { isInModal: false })
              ]))
            : _createCommentVNode("", true)
        ], 64))
  ], 64))
}