import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "c-starRating-container" }
const _hoisted_2 = ["checked", "aria-label", "name", "id", "tabindex"]
const _hoisted_3 = ["for"]
const _hoisted_4 = ["checked", "aria-label", "name", "id", "tabindex"]
const _hoisted_5 = ["for"]
const _hoisted_6 = ["checked", "aria-label", "name", "id", "tabindex"]
const _hoisted_7 = ["for"]
const _hoisted_8 = ["checked", "aria-label", "name", "id", "tabindex"]
const _hoisted_9 = ["for"]
const _hoisted_10 = ["checked", "aria-label", "name", "id", "tabindex"]
const _hoisted_11 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!

  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass([{
            hasError: _ctx.hasError,
            'c-starRating--small': _ctx.size === 'small',
            'c-starRating--xSmall': _ctx.size === 'xSmall',
            'c-starRating--readonly': _ctx.readonly
        }, "c-starRating"])
  }, [
    _createElementVNode("span", _hoisted_1, [
      _createElementVNode("input", {
        onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateRating($event.target.value))),
        checked: _ctx.modelValue === '1',
        "aria-label": _ctx.$t('Rate {rating} out of 5', { rating: '1' }),
        name: _ctx.starRatingName,
        id: `${_ctx.starRatingName}-1`,
        tabindex: _ctx.tabIndex,
        class: "c-starRating-radioButton u-assistive-hidden",
        type: "radio",
        value: "1"
      }, null, 40, _hoisted_2),
      _createElementVNode("label", {
        class: "c-starRating-label c-starRating-label-1-of-5",
        for: `${_ctx.starRatingName}-1`,
        role: "presentation"
      }, null, 8, _hoisted_3),
      _createElementVNode("input", {
        onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateRating($event.target.value))),
        checked: _ctx.modelValue === '2',
        "aria-label": _ctx.$t('Rate {rating} out of 5', { rating: '2' }),
        name: _ctx.starRatingName,
        id: `${_ctx.starRatingName}-2`,
        tabindex: _ctx.tabIndex,
        class: "c-starRating-radioButton u-assistive-hidden",
        type: "radio",
        value: "2"
      }, null, 40, _hoisted_4),
      _createElementVNode("label", {
        class: "c-starRating-label c-starRating-label-2-of-5",
        for: `${_ctx.starRatingName}-2`,
        role: "presentation"
      }, null, 8, _hoisted_5),
      _createElementVNode("input", {
        onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateRating($event.target.value))),
        checked: _ctx.modelValue === '3',
        "aria-label": _ctx.$t('Rate {rating} out of 5', { rating: '3' }),
        name: _ctx.starRatingName,
        id: `${_ctx.starRatingName}-3`,
        tabindex: _ctx.tabIndex,
        class: "c-starRating-radioButton u-assistive-hidden",
        type: "radio",
        value: "3"
      }, null, 40, _hoisted_6),
      _createElementVNode("label", {
        class: "c-starRating-label c-starRating-label-3-of-5",
        for: `${_ctx.starRatingName}-3`,
        role: "presentation"
      }, null, 8, _hoisted_7),
      _createElementVNode("input", {
        onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.updateRating($event.target.value))),
        checked: _ctx.modelValue === '4',
        "aria-label": _ctx.$t('Rate {rating} out of 5', { rating: '4' }),
        name: _ctx.starRatingName,
        id: `${_ctx.starRatingName}-4`,
        tabindex: _ctx.tabIndex,
        class: "c-starRating-radioButton u-assistive-hidden",
        type: "radio",
        value: "4"
      }, null, 40, _hoisted_8),
      _createElementVNode("label", {
        class: "c-starRating-label c-starRating-label-4-of-5",
        for: `${_ctx.starRatingName}-4`,
        role: "presentation"
      }, null, 8, _hoisted_9),
      _createElementVNode("input", {
        onChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.updateRating($event.target.value))),
        checked: _ctx.modelValue === '5',
        "aria-label": _ctx.$t('Rate {rating} out of 5', { rating: '5' }),
        name: _ctx.starRatingName,
        id: `${_ctx.starRatingName}-5`,
        tabindex: _ctx.tabIndex,
        class: "c-starRating-radioButton u-assistive-hidden",
        type: "radio",
        value: "5"
      }, null, 40, _hoisted_10),
      _createElementVNode("label", {
        class: "c-starRating-label c-starRating-label-5-of-5",
        for: `${_ctx.starRatingName}-5`,
        role: "presentation"
      }, null, 8, _hoisted_11),
      _createVNode(_component_icon, {
        icon: "stars-error",
        class: "c-starRating-stars c-starRating-stars--error",
        role: "presentation"
      }),
      _createVNode(_component_icon, {
        icon: "stars-empty",
        class: "c-starRating-stars c-starRating-stars--empty",
        role: "presentation"
      }),
      _createVNode(_component_icon, {
        style: _normalizeStyle({ width: _ctx.visibleStarsWidth }),
        icon: "stars-filled",
        class: "c-starRating-stars c-starRating-stars--filled",
        role: "presentation"
      }, null, 8, ["style"])
    ])
  ], 2))
}