
import { Vue, Options } from 'vue-class-component';
import Icon from './Icon.vue';

@Options({
    components: {
        Icon
    }
})
export default class Skeleton extends Vue {
    skeletonType = '';

    created(): void {
        this.setSkeletonType();
    }

    async setSkeletonType(): Promise<void> {
        await this.$router.isReady();
        this.skeletonType = this.$route.name === 'Review' ? 'review' : 'general';
    }
}
