import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8cc27ca6"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["aria-label"]
const _hoisted_2 = { class: "c-modal-body-inner u-padding--medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!

  return (_ctx.isModalVisible)
    ? (_openBlock(), _createBlock(_Teleport, {
        key: 0,
        to: "body"
      }, [
        _createElementVNode("div", {
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.onModalClose({ confirmation: false })), ["prevent"])),
          class: "c-modal"
        }),
        _createElementVNode("div", {
          id: "modalBody",
          class: _normalizeClass([_ctx.okeClass, "c-modal-body"])
        }, [
          _createElementVNode("div", {
            onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.onModalClose({ confirmation: false })), ["prevent"])),
            class: "c-modal-body-removeButton"
          }, [
            _createElementVNode("button", {
              "aria-label": _ctx.$t('Close Dialog'),
              class: "c-modal-body-removeButton-inner"
            }, [
              _createVNode(_component_icon, {
                icon: "close",
                class: "c-icon--closeModal"
              })
            ], 8, _hoisted_1)
          ]),
          _createElementVNode("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "default", {}, () => [
              _createElementVNode("button", {
                onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.onModalClose({ confirmation: true })), ["prevent"]))
              }, _toDisplayString(_ctx.$t('Yes')), 1),
              _createElementVNode("button", {
                onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.onModalClose({ confirmation: false })), ["prevent"]))
              }, _toDisplayString(_ctx.$t('No')), 1)
            ], true)
          ])
        ], 2)
      ]))
    : _createCommentVNode("", true)
}