import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/images/product-placeholder.png'


const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["src", "width", "height", "alt"]
const _hoisted_3 = ["width", "height", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isProductImageVisible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.imageUrl)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _ctx.imageUrl,
              width: _ctx.imageWidth,
              height: _ctx.imageHeight,
              class: _normalizeClass([[_ctx.productImageSizingModeClass, _ctx.imageClass], "o-productImage"]),
              alt: _ctx.$t('Product Image')
            }, null, 10, _hoisted_2))
          : (_ctx.showPlaceholderIfEmpty)
            ? (_openBlock(), _createElementBlock("img", {
                key: 1,
                width: _ctx.imageWidth,
                height: _ctx.imageHeight,
                class: _normalizeClass([[_ctx.imageClass], "o-productImage o-productImage--cover"]),
                alt: _ctx.$t('Product Image'),
                src: _imports_0
              }, null, 10, _hoisted_3))
            : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}