import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icons/facebook-updated.svg'


const _withScopeId = n => (_pushScopeId("data-v-599d5823"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "c-signIn" }
const _hoisted_2 = { class: "c-signIn-button-icon" }
const _hoisted_3 = ["alt"]
const _hoisted_4 = { class: "u-paddingRightUI--xSmall" }
const _hoisted_5 = {
  ref: "googleSignInButton",
  class: "c-signIn-button--google"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bubble = _resolveComponent("bubble")!
  const _directive_analytics = _resolveDirective("analytics")!
  const _directive_prevent_click_in_preview = _resolveDirective("prevent-click-in-preview")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showAskEmailPermission)
      ? (_openBlock(), _createBlock(_component_bubble, {
          key: 0,
          content: _ctx.askEmailPermission,
          okeClass: "u-marginBottomUI--small",
          status: "info"
        }, null, 8, ["content"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.showFacebookLogin)
        ? _withDirectives((_openBlock(), _createElementBlock("button", {
            key: 0,
            type: "button",
            onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.facebookLogin && _ctx.facebookLogin(...args)), ["prevent"])),
            class: "c-signIn-button c-signIn-button--facebook"
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("img", {
                alt: _ctx.$t('Continue with {socialMedia}', { socialMedia: 'Facebook' }),
                src: _imports_0,
                class: "c-signIn-button-image"
              }, null, 8, _hoisted_3)
            ]),
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('Continue with {socialMedia}', { socialMedia: 'Facebook' })), 1)
          ], 512)), [
            [_directive_analytics, {
                eventName: 'click-login',
                label: 'socialMediaType',
                value: 'facebook'
            }],
            [_directive_prevent_click_in_preview]
          ])
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, null, 512)
    ])
  ], 64))
}